<template>
  <div>
    <div class="resultsPatsnt">
      <div class="title_resultsPatsnt">
        <div class="title_resultsPatsnt_text">
          {{ $t("message.patient_report") }}
        </div>
        <el-button
          v-if="lastHistory.is_ended != 0"
          disabled
          icon="el-icon-edit"
          @click="documentOpen()"
          type="primary"
          round
          >{{ $t("message.write_conclusions") }}</el-button
        >
        <el-button
          v-else
          icon="el-icon-edit"
          @click="documentOpen()"
          type="primary"
          round
          >{{ $t("message.write_conclusions") }}</el-button
        >
      </div>

      <div class="itme_resultsPatsnt" v-if="list.length != 0">
        <table class="table mt-4" v-loading="loadingData">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("message.services") }}</th>
              <th>{{ $t("message.doctor") }}</th>
              <th>{{ $t("message.specialities") }}</th>
              <th>{{ $t("message.date") }}</th>
              <th>{{ $t("message.settings") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ser, index) in list"
              :key="'order-create-in-header' + index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{ ser.service ? ser.service.name : "" }}
              </td>
              <td>
                {{ ser.doctor ? ser.doctor.name : "" }}
                {{ ser.doctor ? ser.doctor.surname : "" }}
                {{ ser.doctor ? ser.doctor.patronymic : "" }}
              </td>
              <td>
                {{
                  ser.doctor
                    ? ser.doctor.speciality[0]
                      ? ser.doctor.speciality[0].name
                      : ""
                    : ""
                }}
              </td>
              <td>
                {{ ser.created_at }}
              </td>
              <td class="we10">
                <div class="d-flex">
                  <el-button
                    v-if="ser.doctor_id == current_user_id"
                    icon="el-icon-edit"
                    @click="edit(ser)"
                    type="primary"
                    round
                    >{{ $t("message.update") }}</el-button
                  >
                  <el-button
                    @click="show(ser)"
                    icon="el-icon-view"
                    type="success"
                    round
                    >{{ $t("message.conclusions") }}</el-button
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <el-drawer
          size="55%"
          :visible.sync="drawer.update.status"
          :with-header="false"
          :ref="drawer.update.name"
          :before-close="beforeClose"
          @opened="drawerOpened(drawer.update.component)"
          @closed="drawerClosed(drawer.update.component)"
        >
          <crm-update
            :drawer-name="drawer.update.name"
            :ref="drawer.update.component"
            :selected="selectedModel"
          />
        </el-drawer>

        <el-drawer
          title="Показать заключение"
          :visible.sync="drawerShow"
          size="70%"
          :drawer="drawerShow"
          @open="reopenShow = true"
          @close="reopenShow = false"
          @closed="emptyModel"
        >
          <div>
            <crm-show
              :reloadModel="reopenShow"
              @open="reopenShow = true"
              @c-close="reloadIfChanged('drawerShow')"
              @closed="emptyModel"
            ></crm-show>
          </div>
        </el-drawer>
      </div>
    </div>

    <el-dialog
      title="Выберите шаблон"
      :append-to-body="true"
      :visible.sync="outerVisible"
      width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
            clearable
            :placeholder="$t('message.search_template')"
            v-model="filterText"
          ></el-input>
          <el-tree
            class="mt-2 filter-tree"
            :data="data"
            node-key="id"
            :filter-node-method="filterNode"
            ref="tree"
            @node-click="handleNodeClick"
            :expand-on-click-node="false"
            show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> Закрыть </el-button>
      </div>
    </el-dialog>

    <el-drawer
      size="90%"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      :before-close="beforeClose"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>

    <el-drawer :visible.sync="drawer2" size="70%" :with-header="false">
      <PatientConclusionFind />
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/patientConclusionCreate";
import PatientConclusionFind from "./components/patientConclusionFind";
import CrmUpdate from "./components/patientConclusionUpdate";
import CrmShow from "./components/patientConclusionShow";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";

import drawer from "@/utils/mixins/drawer";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [drawer, list],
  name: "patientConclusion",
  data() {
    return {
      outerVisible: false,
      form: [],
      drawerUpdate: false,
      drawerShow: false,
      drawer2: false,
      loadingData: false,
      isItemsModelVisible: false,
      isLoadingItems: false,
      selectedModel: null,
      backUrl: process.env.VUE_APP_URL_DOCS,
      reopenUpdate: false,
      reopenShow: false,
      filterText: "",
      excel_data: [],
      service_id: null,
      data: null,
      excel_fields: {},
      drawer: {
        create: {
          name: "create",
          component: "componentCreate",
          status: false,
        },
        update: {
          name: "update",
          component: "componentUpdate",
          status: false,
        },
      },
    };
  },
  components: {
    CrmUpdate,
    CrmShow,
    CrmCreate,
    PatientConclusionFind,
  },
  computed: {
    ...mapGetters({
      current_user_id: "hospitalizationConclusions/current_user_id",
      list: "hospitalizationConclusions/list",
      columns: "hospitalizationConclusions/columns",
      pagination: "hospitalizationConclusions/pagination",
      filter: "hospitalizationConclusions/filter",
      sort: "hospitalizationConclusions/sort",
      states: "states/list",
      token: "auth/token",
      model: "orders/relation_model",
      user_id: "auth/id",
      activeCode: "hospitalPatients/activeCode",
      lastHistory: "dailyTreatments/lastHistory",
    }),
    getId() {
      return this.$route.params.id;
    },
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async created() {},
  watch: {
    columns: {
      handler: function () {},
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    lastHistory: {
      handler: function (val, oldVal) {
        this.showModels({
          hospital_patient_id: this.getId,
          id: val.id,
        })
          .then((res) => {})
          .catch((err) => {
            this.$alert(err);
          });
      },
    },
  },
  async mounted() {
    this.loadingData = true;
    var vm = this;
    this.categoryDocumentationList({ relation: true })
      .then((res) => {
        this.data = res.data.category_forms;
      })
      .catch((err) => {
        console.log(err);
      });
    this.showModels({ hospital_patient_id: vm.getId, id: this.lastHistory.id })
      .then((res) => {
        this.loadingData = false;
      })
      .catch((err) => {
        this.loadingData = false;
        this.$alert(err);
      });
  },
  methods: {
    ...mapActions({
      categoryDocumentationList: "categoryDocumentation/index",
      updateList: "hospitalizationConclusions/index",
      updateSort: "hospitalizationConclusions/updateSort",
      updateFilter: "hospitalizationConclusions/updateFilter",
      updateColumn: "hospitalizationConclusions/updateColumn",
      updatePagination: "hospitalizationConclusions/updatePagination",
      showModel: "hospitalizationConclusions/show",
      showModels: "hospitalizationConclusions/indexitems",
      editModel: "hospitalizationConclusions/show",
      empty: "hospitalizationConclusions/empty",
      delete: "hospitalizationConclusions/destroy",
      refreshData: "hospitalizationConclusions/refreshData",
    }),
    fetchData() {
      this.loadingData = true;
      var vm = this;
      this.showModels({
        hospital_patient_id: vm.getId,
        id: this.activeCode,
      })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$alert(err);
        });
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room?fileName=" +
          model.name +
          "&service_id=" +
          model.service_id +
          "&hospitalization_id=" +
          model.id +
          "&user=" +
          this.token +
          "&id=" +
          this.$route.params.id +
          "&status=edit"
      );
      link.setAttribute("target", "_blank");
      link.click();

      return;
      // this.selectedModel = model;
      // this.drawer.update.status = true;
    },
    async show(model) {
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room?fileName=" +
          model.name +
          "&user=" +
          this.token +
          "&id=" +
          this.$route.params.id +
          "&status=show"
      );
      link.setAttribute("target", "_blank");
      link.click();

      return;
      // this.$loadingCursor("wait");
      // await this.showModel(model.id)
      //   .then((res) => {
      //     this.$loadingCursor("default");
      //     this.drawerShow = true;
      //   })
      //   .catch((err) => {
      //     this.$loadingCursor("default");
      //   });
    },
    destroy(model) {
      this.$confirm(i18n.t("message.do_you_want"), i18n.t("message.warning"), {
        confirmButtonText: i18n.t("message.yes"),
        cancelButtonText: i18n.t("message.cancel"),
        type: "warning",
      })
        .then(() => {
          this.$loadingCursor("wait");
          this.delete(model.id)
            .then((res) => {
              this.$alert(res);
              this.$loadingCursor("default");
            })
            .catch((err) => {
              this.$loadingCursor("default");
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: i18n.t("message.cancel"),
          });
        });
    },
    documentOpen() {
      this.outerVisible = true;
      // this.drawer.create.status = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleNodeClick(node, data, value) {},
    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();
      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }

      this.createModal(this.service_id, template_id);
    },
    async createModal(service_id, template_id) {
      // var name =
      //   model.id + " " + model.patient.surname + " " + model.patient.first_name;
      var link = document.createElement("a");

      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation_patient_room?template_id=" +
          template_id +
          "&user=" +
          this.token +
          "&id=" +
          this.$route.params.id +
          "&status=create"
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();

      return;
    },
    dialogClose() {
      this.$refs.tree.setCheckedNodes([]);
      this.outerVisible = false;
      this.form = {};
      this.model = null;
    },
    beforeClose(done) {
      this.empty();
      this.refreshData();
      done();
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>
<style lang="scss" >
.itme_resultsPatsnt {
  padding: 20px;
}
.we10 {
  width: 220px;
  text-align: right;
}
</style>